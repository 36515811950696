<template>
  <div v-if="loading" class="loader"><loader></loader></div>
  <transition-group
    v-else-if="!loading && ideas && ideas.length > 0 && !noIdeas"
    ref="questionsList"
    class="ideas-set"
    name="flip-list"
    tag="div"
  >
    <div
      v-for="idea in ideas"
      :key="idea.id"
      class="idea"
      :style="{
        borderColor: !themeShow ? getTableColor(idea.table.name) : '#c1c6c8',
      }"
    >
      <div class="idea-body">
        <p
          v-for="(item, index) in getSplitStroke(idea.idea)"
          :key="index"
          class="idea-text"
        >
          {{ item }}
        </p>
      </div>
      <div class="bottom-block">
        <div class="table">
          <span v-if="themeShow">{{
            idea.user.fio || $t("common.guest")
          }}</span>
          <span v-else
            >{{ $t("Brainstorm.table") }} №{{ idea.table.name }}</span
          >
        </div>
        <div class="controls">
          <div
            v-if="idea.is_mine"
            class="delete ctrl-btn"
            @click="deleteMyIdea(idea.id)"
          >
            <i class="icon-trash"></i> <span>{{ $t("common.delete") }}</span>
          </div>
          <div
            class="like ctrl-btn"
            :class="{ 'my-like': idea.is_liked }"
            @click="toggleLike(idea.id)"
          >
            <i class="icon-like"></i> <span>{{ idea.likes || 0 }}</span>
          </div>
        </div>
      </div>
    </div>
  </transition-group>
  <div v-else class="no-ideas">{{ $t("Brainstorm.suggestiIdea") }}!</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import getTableColorByNumber from "@/services/TableColorsService";
import Loader from "@/components/common/elements/Loader";
import { brainstorm, room } from "@/store/modules/store.namespaces";
import {
  DELETE_IDEA,
  SWITCH_IDEA_LIKE,
} from "@/store/modules/brainstorm/action-types";
import { REMOVE_BRAINSTORM_IDEA } from "@/store/modules/brainstorm/mutation-types";

export default {
  name: "TableQuestions",
  components: { Loader },
  props: ["loading", "noIdeas"],
  computed: {
    ...mapState(brainstorm, {
      ideas: "roundedTableIdeas",
    }),
    ...mapState(room, {
      themeShow: state => state.roomInfo.theme_show,
    }),
  },
  methods: {
    ...mapActions(brainstorm, {
      switchIdeaLike: SWITCH_IDEA_LIKE,
      deleteIdea: DELETE_IDEA,
    }),
    ...mapMutations(brainstorm, {
      removeIdea: REMOVE_BRAINSTORM_IDEA,
    }),
    getTableColor(tableName) {
      return getTableColorByNumber(tableName);
    },
    toggleLike(id) {
      this.switchIdeaLike(id);
    },
    deleteMyIdea(id) {
      // fixme multiple
      this.removeIdea(id);
      this.deleteIdea(id);
    },
    getSplitStroke(string) {
      return string.split("\n");
    },
  },
};
</script>

<style scoped lang="less">
.flip-list-move {
  transition: transform 1s !important;
  z-index: 111;
  position: relative;
  background: #f3fcf4 !important;
}
.flip-list-move ~ .questions-list__item:not(.flip-list-move) {
  opacity: 0.5;
}
.loader {
  margin: 15px auto;
}
.no-ideas {
  text-align: center;
  margin: 15px 0;
  font-size: 18px;
  color: #a89b9d;
}
.ideas-set {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 100%;
  max-width: 600px;
  padding: 0 10px;
  margin-top: 15px;
  overflow: auto;
  .idea {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 12px 12px 0;
    margin: 0 auto 10px;
    max-width: 600px;
    width: 100%;
    background-color: white;
    .idea-body {
      font-size: 15px;
      line-height: 19px;
      padding-bottom: 5px;
      word-wrap: break-word;
    }
    .bottom-block {
      margin-top: 5px;
      padding-bottom: 12px;
      display: flex;
      justify-content: space-between;
      .table {
        font-size: 13px;
        color: #9d9d9d;
        flex-shrink: 0;
        span {
          vertical-align: middle;
          display: inline-block;
        }
        /*.table-color-icon {*/
        /*display: inline-block;*/
        /*background-color: #ebebeb;*/
        /*width: 10px;*/
        /*height: 10px;*/
        /*border-radius: 5px;*/
        /*margin-right: 5px;*/
        /*}*/
      }
      .controls {
        display: flex;
        flex-shrink: 0;
        .ctrl-btn {
          display: inline-block;
          font-size: 13px;
          cursor: pointer;
          i {
            margin-right: 5px;
            color: #f28b00;
            display: inline-block;
          }
          span {
            color: #9d9d9d;
            display: inline-block;
            min-width: 12px;
          }
          &:hover {
            transform: scale(1.05);
          }
        }
        .delete {
          margin-right: 18px;
          &:hover {
            span {
              color: #f28b00;
            }
          }
        }
        .like {
          &:hover {
            i {
              &:before {
                color: transparent;
                background: url(~@/assets/images/simple-lines-forfilled-like.svg)
                  no-repeat center center;
              }
            }
          }
          &.my-like {
            span {
              color: #f28b00;
            }
            i {
              &:before {
                color: transparent;
                background: url(~@/assets/images/simple-lines-forfilled-like.svg)
                  no-repeat center center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
