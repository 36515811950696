<template>
  <div class="messenger-container">
    <div ref="testWidth" class="test-message-width">@{{ message }}</div>
    <div class="messenger">
      <form
        class="messenger__form"
        v-on:keydown="onMsgKeyDown($event)"
        @submit.prevent="sendBrainstormIdea"
      >
        <textarea
          ref="textarea"
          v-model="message"
          class="messenger__input"
          rows="5"
          :placeholder="$t('Brainstorm.suggestiIdea') + '...'"
          :class="{ 'messenger__input--no-padding': addPadding }"
          :maxlength="maxLength"
        ></textarea>
        <span :class="{ error: !counter }" class="counter">{{ `${counter}/${maxLength}` }}</span>
        <ws-button icon lg class="messenger__send" type="submit" color="default">
          <ws-icon light xxl color="primary">paper-plane</ws-icon>
        </ws-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { brainstorm, room } from "@/store/modules/store.namespaces";
import { GET_ROUNDED_TABLE_IDEAS, SEND_IDEA } from "@/store/modules/brainstorm/action-types";

import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  components: {
    WsButton,
    WsIcon,
  },
  props: ["currentTab"],
  data() {
    return {
      // Добавлять ли отступы у текстового поля
      addPadding: false,
      // Текст сообщения
      message: "",
      // Максимальное количество символов
      maxLength: 500,
    };
  },
  computed: {
    ...mapState(room, {
      themeShow: state => state.roomInfo.theme_show,
    }),
    /**
     * Счетчик оставшихся символов.
     *
     * @returns { number } - число оставшихся символов.
     * */
    counter() {
      return this.maxLength - this.message.length;
    },
  },
  watch: {
    message() {
      this.addPadding = this.checkPadding();
    },
  },
  methods: {
    ...mapActions(brainstorm, {
      getRoundedTableIdeas: GET_ROUNDED_TABLE_IDEAS,
      sendIdea: SEND_IDEA,
    }),
    onMsgKeyDown(event) {
      const MOBILE_BROWSERS_REGEXP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const { textarea } = this.$refs;
      if (
        !event.ctrlKey &&
        event.keyCode === 13 &&
        !MOBILE_BROWSERS_REGEXP.test(navigator.userAgent)
      ) {
        event.preventDefault();
        this.sendBrainstormIdea();
        textarea.blur();
        return;
      }
      if (
        event.ctrlKey &&
        event.keyCode === 13 &&
        !MOBILE_BROWSERS_REGEXP.test(navigator.userAgent)
      ) {
        this.message += "\n";
      }
    },
    checkPadding() {
      const PADDING = 60;
      const textArea = this.$refs.textarea;
      if (!textArea || !this.message) {
        return false;
      }
      const textAreaWidth = textArea.clientWidth - PADDING;
      const messageWidth = this.$refs.testWidth.clientWidth;
      return !!(messageWidth > textAreaWidth || this.message.indexOf("\n") !== -1);
    },
    async sendBrainstormIdea() {
      if (!this.message) return;
      await this.sendIdea({ idea: this.message });
      this.getRoundedTableIdeas();
      this.message = "";
    },
  },
};
</script>

<style scoped lang="less">
.messenger {
  flex-shrink: 0;
  width: 100%;
  max-width: 594px;
  height: 59px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  background-color: #ffffff;
  border: solid 1px #ebebeb;
  border-radius: 8px;
}

.messenger__form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;

  .counter {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 10px;

    &.error {
      color: red;
    }
  }
}

/* stylelint-disable declaration-no-important */
.messenger__send {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 57px;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-radius: 0;
}

.messenger__input {
  box-sizing: border-box;
  flex-shrink: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 19px 50px 0 10px;
  margin: 0;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: 19px;
  resize: none;
  color: #000000;
  text-align: left;
  outline: none !important;
  transition: all ease 0.3s;

  &--no-padding {
    padding-top: 0;
  }
}

.test-message-width {
  position: absolute;
  width: auto;
  height: auto;
  font-family: ProximaNova, "Helvetica", sans-serif;
  font-size: 16px;
  white-space: nowrap;
  visibility: hidden;
}

.messenger-container {
  position: relative;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 0 8px;
  border: none;
  border-top: none;
  border-left: none;
}

.speaker-select-block {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 594px;
  margin: 0 auto 3px;
  cursor: pointer;
}
</style>
