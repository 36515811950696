<template>
  <div class="theme-idea">
    <template>
      <div class="theme-idea__header">
        <div class="theme-idea__header-name" :style="headerStyles">
          {{ $t("Brainstorm.yourIdeasTopics") }}
        </div>

        <div class="filtr-dropdown">
          <div class="filtr-dropdown__name" @click="openFilter = !openFilter">
            {{ currentFilter | moment("DD MMMM") }}
          </div>

          <div v-show="openFilter" class="filtr-dropdown__list">
            <div
              v-for="(item, index) in dateFilterItems"
              :key="`${item}_${index}`"
              class="filtr-dropdown__item"
              @click="selectFiltr(item)"
            >
              {{ item | moment("DD MMMM") }}
            </div>
          </div>
        </div>
      </div>

      <div class="theme-idea__list">
        <div
          v-for="item in themeIdeas"
          :key="item.id"
          class="theme-idea__item"
          @click="[openThemeIdea(item.id), statusIdea()]"
        >
          <div v-if="item.filter_date" class="theme-idea__item__time">
            {{ item.filter_date | moment("DD MMMM") }}
          </div>
          <div class="theme-idea__item__name" v-html="item.name"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { brainstorm, room } from "@/store/modules/store.namespaces";
import {
  GET_BRAINSTORMS,
  GET_ROUNDED_TABLE_IDEAS,
} from "@/store/modules/brainstorm/action-types";
import {
  SET_BRAINSTORM_FILTER,
  SET_BRAINSTORM_IDEA_ID,
} from "@/store/modules/brainstorm/mutation-types";

export default {
  name: "TableThemeIdea",
  data() {
    return {
      loading: false,
      curentFilter: "",
      openFilter: false,
    };
  },
  computed: {
    ...mapState(brainstorm, ["roomBrainstorms", "brainstormFilter"]),
    ...mapState(room, ["roomInfo"]),
    ...mapState(room, {
      themeStyleColors: state => state.styleColors,
    }),
    currentFilter() {
      return this.brainstormFilter;
    },
    dateFilterItems() {
      let dateFilterItems = [
        ...this.roomBrainstorms.reduce((accum, brainstorm) => {
          if (brainstorm.filter_date) {
            accum.add(brainstorm.filter_date);
          }
          return accum;
        }, new Set()),
      ];
      if (this.invalidateCurrentDate(dateFilterItems)) {
        this.setBrainstromFilter(dateFilterItems[0]);
      }

      return dateFilterItems;
    },
    themeIdeas() {
      return this.roomBrainstorms.filter(item => {
        return (
          item.filter_date === this.currentFilter || item.filter_date === null
        );
      });
    },
    headerStyles() {
      if (this.themeStyleColors.innerHeadersColor) {
        return `color: ${this.themeStyleColors.innerHeadersColor}`
      }
    },
  },
  methods: {
    ...mapActions(brainstorm, {
      getBrainstorms: GET_BRAINSTORMS,
      getRoundedTableIdeas: GET_ROUNDED_TABLE_IDEAS,
    }),
    ...mapMutations(brainstorm, {
      setBrainstromFilter: SET_BRAINSTORM_FILTER,
      setUserBrainstromIdeaId: SET_BRAINSTORM_IDEA_ID,
    }),
    statusIdea() {
      this.$emit("statusIdea", "active");
    },
    selectFiltr(val) {
      this.setBrainstromFilter(val);
      this.openFilter = false;
    },
    openThemeIdea(id) {
      this.setUserBrainstromIdeaId(id);
      this.getRoundedTableIdeas();
    },
    invalidateCurrentDate(dates) {
      return (
        !this.currentFilter || !dates.some(date => date === this.currentFilter)
      );
    },
  },
  created() {
    if (this.roomInfo.room_id) {
      this.getBrainstorms();
    }
  },
};
</script>

<style scoped lang="less">
.filtr-dropdown {
  position: relative;
  &__name {
    position: relative;
    font-size: 18px;
    cursor: pointer;
    padding-right: 20px;
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 5px;
      height: 5px;
      border-width: 0px 1px 1px 0px;
      border-style: solid;
      border-color: var(--base-color);
      transform: translateY(-3px) rotate(45deg);
    }
  }

  &__list {
    position: absolute;
    right: 0;
    top: ~"calc(100% + 20px)";
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    z-index: 10;
  }

  &__item {
    font-size: 16px;
    cursor: pointer;
    padding: 12px 18px;
    white-space: nowrap;
  }

  &__item:hover {
    background: var(--base-color);
    color: #ffffff;
  }
}

.theme-idea {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding: 16px 8px 8px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 40px;

    &-name {
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    width: ~"calc(50% - 8px)";
    margin: 8px 0;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #c1c6c8;
    padding: 13px 55px 13px 17px;
    position: relative;
    min-height: 90px;
    background-color: white;

    @media (max-width: 640px) {
      width: 100%;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%) rotate(-45deg);
      border-width: 0px 1px 1px 0px;
      border-style: solid;
      border-color: var(--base-color);
      width: 10px;
      height: 10px;
    }

    &__time {
      font-size: 10px;
      color: #b2b2b2;
      margin-bottom: 10px;
    }

    &__name {
      font-size: 15px;
      line-height: 20px;
      // max-height: 39px;
      overflow: hidden;
    }
  }
}
</style>
