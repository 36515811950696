import Color from "color";

const baseTableColors = [
  "#f28b00",
  "#ff7676",
  "#73c815",
  "#5ed5ff",
  "#c37eff",
  "#ffda65",
  "#ff6da9",
  "#6294ff",
  "#c8c8c8",
  "#caff70",
  "#44dfd2",
  "#c19e80"
];

const colorsCount = baseTableColors.length;

let tableColors = {};

const getTableColorByNumber = function(number) {
  if (!tableColors[number]) {
    if (number < colorsCount) {
      tableColors[number] = baseTableColors[number];
    } else {
      var tint = Math.floor(number / colorsCount);
      var color = number % colorsCount;
      tableColors[number] =
        tint >= 10
          ? baseTableColors[color]
          : Color(baseTableColors[color])
              .darken(tint * 0.1)
              .string();
    }
  }
  return tableColors[number];
};

export default getTableColorByNumber;
