<template>
  <div class="rounded-table">
    <div v-if="currentBrainstormTitle && !themeShow" class="brainstorm-title">
      {{ currentBrainstormTitle }}
    </div>
    <div v-if="themeShow" class="back-btn" @click="allIdea">
      {{ $t("common.back") }}
    </div>
    <div v-if="allTablesAvailable && !themeShow" class="switch-tabs">
      <div
        class="pill"
        :class="{ active: currentTab === 'group' }"
        @click="switchTab('group')"
      >
        {{ $t("Brainstorm.groupIdeas") }}
      </div>
      <div
        class="pill"
        :class="{ active: currentTab === 'all' }"
        @click="switchTab('all')"
      >
        {{ $t("Brainstorm.allIdeas") }}
      </div>
    </div>
    <table-questions :loading="loading" :no-data="noIdeas" />
    <div class="messenger-wrapper">
      <table-messenger class="messenger" :currentTab="currentTab" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import TableQuestions from "./TableQuestions";
import TableMessenger from "./TableMessenger";
import { brainstorm, room } from "@/store/modules/store.namespaces";
import {
  BRAINSTORM_ID,
  BRAINSTORM_TITLE,
} from "@/store/modules/brainstorm/getter-types";
import {
  SET_BRAINSTORM_IDEA_ID,
  SET_BRAINSTORM_IDEAS,
  SET_CURRENT_BRAINSTORM_TAB,
} from "@/store/modules/brainstorm/mutation-types";
import {
  GET_BRAINSTORMS,
  GET_ROUNDED_TABLE_IDEAS,
} from "@/store/modules/brainstorm/action-types";

//const LIKES_UPDATE_INTERVAL = 3*60*1000;

const LIKES_UPDATE_INTERVAL = 5 * 1000;

let likesTimeout = null;

export default {
  components: {
    TableQuestions,
    TableMessenger,
  },
  props: ["brainstormTitle"],
  data() {
    return {
      currentTab: "group",
      loading: false,
      noIdeas: false,
    };
  },
  computed: {
    ...mapGetters(brainstorm, {
      currentBrainstormId: BRAINSTORM_ID,
      currentBrainstormTitle: BRAINSTORM_TITLE,
    }),
    ...mapState(room, {
      allTablesAvailable: state => state.roomInfo?.Brainstorm?.status == "2",
      themeShow: state => state.roomInfo.theme_show,
    }),
  },
  methods: {
    ...mapActions(brainstorm, {
      getRoundedTableIdeas: GET_ROUNDED_TABLE_IDEAS,
      getBrainstorms: GET_BRAINSTORMS,
    }),
    ...mapMutations(brainstorm, {
      setBrainstormIdeaId: SET_BRAINSTORM_IDEA_ID,
      setCurrentBrainstormTab: SET_CURRENT_BRAINSTORM_TAB,
      setBrainstormIdeas: SET_BRAINSTORM_IDEAS,
    }),
    allIdea() {
      this.setBrainstormIdeaId(null);
      this.$emit("statusIdea", "tables");
    },
    switchTab(tab) {
      this.setCurrentBrainstormTab(tab);
      this.currentTab = tab;
      this.loading = true;
      this.getRoundedTableIdeas()
        .then(() => {
          this.loading = false;
          this.noIdeas = false;
        })
        .catch(() => {
          this.loading = false;
          this.noIdeas = true;
        });
    },
    renewLikesByInterval() {
      likesTimeout = setTimeout(() => {
        this.getRoundedTableIdeas();
        this.renewLikesByInterval();
      }, LIKES_UPDATE_INTERVAL);
    },
  },
  watch: {
    allTablesAvailable(newVal) {
      if (!newVal) {
        this.currentTab = "group";
      }
    },
    currentBrainstormId(newValue) {
      if (newValue) {
        this.getRoundedTableIdeas();
      }
    },
  },
  created() {
    if (!this.themeShow) {
      this.getRoundedTableIdeas()
        .then(resp => {
          this.noIdeas = resp === "NO_DATA";
        })
        .catch(() => {
          this.noIdeas = true;
        });
    }
    this.getBrainstorms();
  },
  mounted() {
    this.getRoundedTableIdeas();
    this.renewLikesByInterval();
  },
  beforeDestroy() {
    if (likesTimeout) {
      clearTimeout(likesTimeout);
    }
    this.setBrainstormIdeas([]);
  },
};
</script>

<style scoped lang="less">
.back-btn {
  position: absolute;
  top: 13px;
  left: 3%;
  color: var(--base-color);
  font-size: 13px;
  line-height: 27px;
  height: 27px;
  padding: 0 28px 0 31px;
  border: 1px solid var(--base-color);
  border-radius: 16px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 13px;
    width: 9px;
    height: 9px;
    transform: rotate(45deg) translateY(-6px);
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: var(--base-color);
  }
}

.rounded-table {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-top: 40px;
  overflow: hidden;

  .brainstorm-title {
    margin: 15px 0;
    font-size: 32px;
    text-align: center;
  }

  .switch-tabs {
    display: inline-block;
    flex-grow: 0;
    border-radius: 16px;
    margin: 15px auto 0;
    font-size: 13px;
    .pill {
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      border: 1px solid #4e4748;
      padding: 5px 15px;
      transition: all 0.3s;

      &:first-of-type {
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
      }

      &:last-of-type {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      &.active {
        background-color: #4e4748;
        color: #fff;
      }
    }
  }

  .messenger-wrapper {
    border-top: 1px solid #ebebeb;
    flex-shrink: 0;
    margin-top: auto;
    padding: 14px 0;
  }
}
</style>
